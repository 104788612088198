<template>
  <div style="margin: auto;">
	<myNav/>
    <div>
      <div class="f_content">
        <div><img class="f_img" src="@/assets/login_img.png" alt="" /></div>
        <div class="f_all">
          <div class="f_title1">{{type==2 ? "密码登录" : "设置密码"}}</div>
          <div v-if="type==2">
			<div class="f_title2">
				<i class="iconfont icon-shouji1"></i>
				<input
				v-model="form1.phone"
				style="border: none; outline: none"
				class="f_title4"
				type="text"
				placeholder="请输入您的电话号码"
				/>
			</div>
				<div class="f_title2" style="margin-top:30px;">
					<i class="iconfont icon-shouji1"></i>
					<input 
					v-model="form1.password"
					style="border: none; outline: none"
					class="f_title4"
					placeholder="请输入密码"
					type="password">
				</div>
			</div>
			<div v-if="type==1">
			<div class="f_title2">
				<i class="iconfont icon-shouji1"></i>
				<input
				v-model="form.password"
				style="border: none; outline: none"
				class="f_title4"
				type="password"
				placeholder="请输入您的密码（6～20位数字和字母任意组合）"
				/>
			</div>
				<div class="f_title2" style="margin-top:30px;">
					<i class="iconfont icon-shouji1"></i>
					<input 
					v-model="form.prePassword"
					style="border: none; outline: none"
					class="f_title4"
					placeholder="再次输入密码"
					type="password">
				</div>
			</div>
          <div class="f_title5" @click="isLogin()">{{type==2 ? "登录" : "设置密码"}}</div>
          <div class="f_title10">
           <!-- <div class="f_title11">忘记密码？</div> -->
            <div class="f_title12" @click="Login()">验证码登录》</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
	import Footer from '@/components/footer'
export default {
  components: {
	Footer
  },
  data() {
    return {
      time: "",
      isCode: "获取验证码",
      num: 5,
      listData1: [],
      newsData: [],
		form1:{password:"",phone:""},
      form: { prePassword: "", password:"",phone:""},
		type:"",//判断登录还是设置密码
    };
  },
  activated() {
		this.form1={password:"",phone:""};
		this.form={ prePassword: "", password:"",phone:""};
		this.form.phone=this.$route.query.phone
		this.type=this.$route.query.id
  },
  methods: {
    //跳转注册页面
    Login(){
      this.$router.push({path:'/Login'})
    },
    //登录注册
    isLogin() {
		if(this.type==1){
			if (this.form.password.length<6) return this.$message({message:'密码必须六位以上',type:'warning'})
			if(!this.form.password) return this.$message({message:'请输入密码',type:'warning'})
			if(this.form.password!=this.form.prePassword) return this.$message({message:'两次密码输入不一致',type:'warning'})
			this.$axios({
				url:"Login/set_modify",
				method:"post",
				data:this.form
			}).then(res=>{
				if(res.data.status==200){
					this.$message({message:'设置密码成功，去登录',type:'warning'})
					this.$router.push({path:'/Login'})
				}
			})
		}else{
			if(!this.form1.phone) return this.$message({message:'请输入电话号码',type:'warning'})
			if(!this.form1.password) return this.$message({message:'请输入密码',type:'warning'})
			if (this.form1.password.length<6) return this.$message({message:'密码必须六位以上',type:'warning'})
			this.$axios({
				url:"Login/login",
				method:"post",
				data:this.form1
			}).then(res=>{
				this.$store.commit('userInfo',res.data)
				if(res.data.code==200){
					this.$message({message:'登录成功',type:'warning'})
					this.$router.push({path:'/index'})
				}else if(res.data.code == 208){
					this.$message({message:'完善资料',type:'warning'})
					this.$router.push({path:'/About'})
				}else{
					return this.$message({message:'密码错误',type:'warning'})
				}
			})
		}
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.f_content {
	width: 1200px;
  display: flex;
  margin: auto;
  .f_img {
    width: 730px;
    height: 549px;
  }
  .f_all {
    width: 470px;
    background-color: #ffffff;
    padding: 0px 47px;
    box-sizing: border-box;
    .f_title1 {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      margin: 21px 0px 56px 0px;
    }
    .f_title2 {
      display: flex;
      align-items: center;
      width: 390px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 5px;
      padding-left: 23px;
      box-sizing: border-box;
      .iconfont {
        color: #333333;
      }
      .f_title4 {
        margin-left: 10px;
        height: 40px;
        width: 400px;
        background: #f5f5f5;
      }
    }
    .f_title5 {
      width: 390px;
      height: 61px;
      background: #337cff;
      opacity: 1;
      border-radius: 5px;
      margin: 47px 0px 16px 0px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .f_title6 {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      text-align: left;
      margin-left: 20px;
    }
    .f_title10 {
      display: flex;
      justify-content: space-between;
      .f_title11 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-align: left;
        margin-left: 20px;
      }
      .f_title12 {
        font-size: 14px;
        font-weight: 400;
        color: #337cff;
        margin-right: 20px;
      }
    }
    .f_title3 {
      display: flex;
      align-items: center;
      margin: 28px 0px;
      .f_title7 {
        width:390px;
        height: 50px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        .iconfont {
          color: #333333;
        }
        .f_title8 {
          margin-left: 10px;
          height: 40px;
          width: 145px;
          background: #f5f5f5;
        }
      }
      .f_title9 {
        width: 132px;
        height: 49px;
        background: #337cff;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 23px;
      }
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
// 	height: 1000px !important;
//   }
/deep/ .el-carousel__container {
  height: 471px;
  width: 841px;
}
/deep/ .el-carousel__indicators--horizontal {
  left: 35%;
}
/deep/.el-carousel__indicators--horizontal {
  padding: 100px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
