import { render, staticRenderFns } from "./appDownload.vue?vue&type=template&id=22cd1b77&scoped=true&"
import script from "./appDownload.vue?vue&type=script&lang=js&"
export * from "./appDownload.vue?vue&type=script&lang=js&"
import style0 from "./appDownload.vue?vue&type=style&index=0&id=22cd1b77&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22cd1b77",
  null
  
)

export default component.exports