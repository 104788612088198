<template>
	<div>
		<!-- <myNav :loginValue="token"/> -->
		<myNav/>
		<login-pop/>
		<div class="content">
		<div class="g_content">
				<div class="gg_content">
					<div class="gg_title1">{{list.type_detail}}</div>
					<div class="gg_all">
						<div class="gg_title2">
							<div class="gg_title3">专业代码：{{list.major_code}}</div>
							<div class="gg_title3">学历层次：{{list.level1==1 ? '本科' : '专科'}}</div>
							<div class="gg_title3">授予学位：{{list.degree}}</div>
						</div>
						<div class="gg_title2">
							<div class="gg_title3">专业人气：{{list.major_mood}}</div>
							<div class="gg_title3">所属学科：{{list.major_title}}</div>
							<div class="gg_title3">修业年限：{{list.system}}</div>
						</div>
					</div>
				</div>
				<div class="g_content1">
					<div @click="xuanZe(index)" :class="index1==index ? 'g_next1' : 'g_next2'" v-for="(item,index) in list2" :key="index">{{item.name}}</div>
				</div>
				<div class="g_title8"></div>
				<div v-if="index1==0">
					<div class="g_title9">
						<div class="g_title10">院校简介</div>
						<div style="padding: 30px;box-sizing: border-box;">
							<span v-html="list.major_detailed" class="g_title11"></span>
						</div>
					</div>
				</div>
				<div v-if="index1==2">
					<div class="b_title1">共{{listData.total}}所</div>
					<div class="b_content" v-for="(item,index) in listData.data" :key="index">
						<div class="b_all" @click="collegeDetail(item)">
							<div><img class="b_img" :src="item.school.logo" alt=""></div>
							<div class="b_title2">
								<div class="b_title3">{{item.school.title}}</div>
								<div class="b_title4">{{item.school.province_name}}</div>
								<div class="b_title5">
									<div class="b_title6">{{item.school.is_pubilc==1 ? '公办' : '民办'}}</div>
									<div class="b_title7" v-if="item.school.f985==1">985</div>
									<div class="b_title8" v-if="item.school.f211==1">211</div>
									<div class="b_title9" v-if="item.school.dual_class">双一流</div>
								</div>
							</div>
						</div>
						<div class="b_title10">
							<i class="iconfont icon-mn_paiming_fill"></i>
							<span class="b_title11">排名{{item.school.zr_rank}}</span>
						</div>
					</div>
					<div class="search_all" style="margin-top:50px;display: flex;justify-content: center;">
					<el-pagination
					background
					layout="prev,pager,next"
					:total="listData.total"
					:page-size="10"
					:pager-count="7"
					:current-page="listData.current_page"
					@current-change="currentChange"
					>
					</el-pagination>
					</div>
				</div>
				<div v-if="index1==1">
					<div class="g_title9">
						<div class="g_title10">就业前景</div>
						<div class="h_content" v-for="(item,index) in distributed_val" :key="index">
							<div class="h_title1">{{item.name}}</div>
							<div class="h_all">
								<div class="h_title2">
									<div class="h_title3" :style="{width: 520*(item.rate/100)+'px',transition: 'width ' + 2*(item.rate/100)+'s'}"></div>
								</div>
								<div class="h_title4">{{item.rate}}%</div>
							</div>
						</div>
					</div>
					<div class="h_title5">数据来源说明</div>
					<div class="h_title6">以上数据来源于大乐教育平台，仅供参考。留学生、高校医院国企等事业编制单位的薪酬样本较少，可能有所偏差。</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				myIndex:0,
				index1:0,
				list2:[{name:'专业概况'},{name:'就业前景	'},{name:'开设院校'}],
				list:{}	,
				id:'',
				listData:[],
				distributed_val:[],
				distributed:[]
				// 学校列表 - 详情
			}
		},
		activated(){
			this.index1=0
			this.id=this.$route.query.id
			this.dataAll(this.id)
		},
		methods: {
			//跳转学校详情页面
			collegeDetail(e){
				this.$store.commit('detail',{school_id:e.school_id})
				this.$router.push({path:'/Detail'})
			},
			//专业概况
			dataAll(e){
				this.$axios({
					url:'major/detail',
					method:'post',
					data:{
						id:e
					}
				}).then(res=>{
					this.list=res.data.data
				})
			},
			//院校专业展示
			nextData(e){
				this.myIndex=e
			},
			xuanZe(i) {
				this.index1 = i
				if(i==2){
					this.$axios({
						url:'major/getSchool',
						method:'post',
						data:{
							id:this.id
						}
					}).then(res=>{
						this.listData=res.data.data
					})
				}else if(i==1){//就业前景
					this.$axios({
						url:'major/major_job',
						method:'post',
						data:{
							id:this.id
						}
					}).then(res=>{
						if (res.status == 200){
							if(res.data)
							{
								this.distributed=res.data.data.address;
								this.distributed_val=res.data.data.industry;
							}
						}
					})
				}
			},
			//分页
			currentChange(e){
				this.$axios({
					url:'major/getSchool',
					method:'post',
					data:{
						id:this.id,
						page:e
					}
				}).then(res=>{
					this.listData=res.data.data
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.g_content{
			margin-top: 20px;
			background-color: #FFFFFF;
			width: 1200px;
			padding: 30px 0px;
			box-sizing: border-box;
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			.g_content1{
				display: flex;
				justify-content:space-between;
				margin-top: 40px;
				.g_next1{
					width: 400px;
					height: 50px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.g_next2{
					width: 400px;
					height: 55px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.g_title8{
				width: 1200px;
				height: 2px;
				background: #337CFF;
			}
			.b_title1{
				font-size:18px;
				font-weight: 400;
				color: #666666;
				margin: 29px 0px 0px 29px;
			}
			.b_content{
				border-bottom: 1px solid #F5F5F5;
				padding-bottom: 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 29px;
				box-sizing: border-box;
				.b_all{
					display: flex;
					.b_img{
						width: 94px;
						height: 94px;
						border-radius: 50%;
						margin-right: 22px;
					}
					.b_title2{
						.b_title3{
							font-size:18px;
							font-weight: 400;
							color: #333333;
						}
						.b_title4{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin: 10px 0px;
						}
						.b_title5{
							display: flex;
							align-items: center;
							.b_title6{
								width: 35px;
								height: 21px;
								background: #F0F5FF;
								border-radius: 4px;
								font-size: 12px;
								font-weight: 400;
								color: #337CFF;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 15px;
							}
							.b_title7{
								width: 32px;
								height: 21px;
								background: #FFF1F0;
								border-radius: 4px;
								font-size: 12px;
								font-weight: 400;
								color: #FF4D4F;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 15px;
							}
							.b_title8{
								width: 28px;
								height: 21px;
								background: #DDF7E2;;
								border-radius: 4px;
								font-size: 12px;;
								font-weight: 400;
								color: #3DB159;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 15px;
							}
							.b_title9{
								width: 35px;
								height: 17px;
								font-size: 12px;
								font-weight: 400;
								color: #3B68DF;
								width: 47px;
								height: 21px;
								background: #F0F5FF;
								border-radius: 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 15px;
							}
						}
					}
				}
				.b_title10{
					display: flex;
					align-items: center;
					.iconfont{
						color: #FECB1E;
						font-size:20px;
					}
					.b_title11{
						font-size:18px;
						font-weight: 400;
						color: #FECB1E;
						margin-left: 10px;
					}
				}
			}
			.g_title9{
				.h_content{
					margin-left: 32px;
					.h_title1{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
					}
					.h_all{
						display: flex;
						align-items: center;
						margin: 7px 0px;
						.h_title2{
						width: 570px;
						height: 16px;
						background: #F5F5F5;
						border-radius: 99px;
							.h_title3{
							width: 292px;
							height: 16px;
							background: #337CFF;
							border-radius: 99px;
							}
						}
						.h_title4{
							font-size: 16px;
							font-weight: 400;
							color: #333333;
							margin-left: 50px;
						}
					}
				}
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					padding: 30px;
					box-sizing: border-box;
				}
			}
			.h_title5{
				font-size:18px;
				font-weight: 400;
				color: #333333;
				margin: 100px 0px 10px 30px;
			}
			.h_title6{
				font-size: 16px;
				font-weight: 400;
				color: #666666;
				margin-left: 30px;
			}
		}
	}
</style>
