<template>
	<div>
		<myNav />
		<div class="content">
			<div class="c_content">
				<img class="c_img" src="@/assets/zhuanjia.png" alt="">
				<div class="c_title1">专家一对一填报</div>
				<div class="cc_all">
					<div class="c_title2">
						<div class="c_title3">
							<i class="iconfont icon-daxue-"></i>
						</div>
						<div class="c_title4">志愿填报1对1</div>
						<div class="c_title5">科学定位院校及专业</div>
					</div>
					<div class="c_title2">
						<div class="c_title3" style="background-color: #F47F35;">
							<i class="iconfont icon-tianxie1"></i>
						</div>
						<div class="c_title4">志愿填报1对1</div>
						<div class="c_title5">科学定位院校及专业</div>
					</div>
					<div class="c_title2">
						<div class="c_title3" style="background-color: #F26E6E;">
							<i class="iconfont icon-zhiyuan"></i>
						</div>
						<div class="c_title4">志愿填报1对1</div>
						<div class="c_title5">科学定位院校及专业</div>
					</div>
					<div class="c_title2">
						<div class="c_title3" style="background-color: #FDAE26;">
							<i class="iconfont icon-jishiben"></i>
						</div>
						<div class="c_title4">志愿填报1对1</div>
						<div class="c_title5">科学定位院校及专业</div>
					</div>
				</div>
				<div class="c_title6">志愿填报重要性</div>
				<div class="c_title7">志愿报的好=高考加30分+理想专业+理想大学+就业前景好</div>
				<div class="c_title8">
					<div>
						<div>
							<img class="c_title9" src="@/assets/p1.png" alt="">
						</div>
						<div class="c_title10">填不好志愿</div>
						<div class="c_title11">高分低就或直接滑档</div>
					</div>
					<div>
						<div>
							<img class="c_title9" src="@/assets/p2.png" alt="">
						</div>
						<div class="c_title10">填不对志愿</div>
						<div class="c_title11">失去选择权，调剂到冷门专业</div>
					</div>
					<div>
						<div>
							<img class="c_title9" src="@/assets/p3.png" alt="">
						</div>
						<div class="c_title10">填不准志愿</div>
						<div class="c_title11">上不到好大学，读不到好专业</div>
					</div>
				</div>
				<div class="c_title6">线上志愿填报-方便学生和家长</div>
				<div class="d_content">
					<div class="d_all">
						<div class="d_title1">线上填报</div>
						<div class="d_title2">
							<div class="d_title3">1</div>
							<span class="d_title4">无差旅费，节约成本</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">2</div>
							<span class="d_title4">服务及时，无需排队</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">3</div>
							<span class="d_title4">线上咨询，24小时在线</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">4</div>
							<span class="d_title4">线上填报，专属群服务</span>
						</div>
					</div>
					<div class="d_all">
						<div class="d_title1">线上填报</div>
						<div class="d_title2">
							<div class="d_title3">1</div>
							<span class="d_title4">成本较高，价格昂贵</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">2</div>
							<span class="d_title4">人多吵闹，需要排队</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">3</div>
							<span class="d_title4">线下咨询，提前预约</span>
						</div>
						<div class="d_title2">
							<div class="d_title3">4</div>
							<span class="d_title4">线下填报，零散服务</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				img: []
			};
		},
		methods: {},
	};

	// <script>
	// 	export default {
	// 		components: {},
	// 		data() {
	// 			return {

	// 			}
	// 		},
	// 		methods: {

	// 		}
	// 	}
	//
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		display: flex;
		justify-content: center;

		.bg_img {
			margin: 20px 0px 20px 0px;
			width: 1200px;
			height: 135px;
		}

		.c_content {
			width: 1200px;
			margin-top: 20px;

			.c_img {
				width: 1200px;
				height: 471px;
				//object-fit: cover;
			}

			.c_title1 {
				font-size: 24px;
				font-weight: 500;
				color: #000000;
				margin: 40px 77px;
			}

			.cc_all {
				display: flex;

				.c_title2 {
					margin: 0px 70px;
					text-align: center;

					.c_title3 {
						width: 120px;
						height: 120px;
						background-color: #337CFF;
						border-radius: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: auto;

						.iconfont {
							color: #FFFFFF;
							font-size: 60px;
						}
					}

					.c_title4 {
						margin: 24px 0px 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #000000;
					}

					.c_title5 {
						font-size: 16px;
						font-weight: 400;
						color: #666666;
					}
				}
			}

			.c_title6 {
				font-size: 24px;
				font-weight: 500;
				color: #000000;
				margin: 80px 0px 20px 0px;
			}

			.d_content {
				display: flex;
				justify-content: space-between;
				padding: 0px 100px;
				box-sizing: border-box;

				.d_all {
					.d_title1 {
						font-size: 16px;
						font-weight: 400;
						color: #000000;
						margin-bottom: 20px;
					}

					.d_title2 {
						margin-bottom: 20px;
						display: flex;
						align-items: center;

						.d_title3 {
							width: 22px;
							height: 22px;
							background: #337CFF;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							font-weight: 400;
							color: #FFFFFF;
							margin-right: 20px;
						}

						.d_title4 {
							font-size: 16px;
							font-weight: 400;
							color: #000000;
						}
					}
				}
			}

			.c_title7 {
				font-size: 16px;
				font-weight: 400;
				color: #666666;
			}

			.c_title8 {
				display: flex;
				justify-content: space-around;
				margin-top: 80px;

				.c_title9 {
					width: 150px;
					height: 150px;
				}

				.c_title10 {
					margin: 24px 0px 10px 0px;
					font-size: 18px;
					font-weight: 400;
					color: #000000;
					text-align: center;
				}

				.c_title11 {
					font-size: 16px;
					font-weight: 400;
					color: #666666;
				}
			}
		}
	}

	.content {
		.bg_img {
			margin: 20px 0px 20px 0px;
			width: 1400px;
			height: 135px;
		}

		.c_content {
			width: 1200px;
			margin-top: 20px;

			.c_img {
				width: 1200px;
				height: 471px;
			}

			.c_all {
				display: flex;
				justify-content: center;
				position: relative;
				margin: 100px 0px 40px 0px;
				height: 70px;

				.c_title1 {
					font-size: 36px;
					font-weight: 600;
					color: #4e6ef2;
				}

				.c_title2 {
					width: 71px;
					height: 8px;
					background: #4e6ef2;
					border-radius: 5px;
					position: absolute;
					bottom: 0px;
					text-align: center;
				}
			}

			.c_title3 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				text-align: left;
				margin-bottom: 30px;
			}

			.c_title4 {
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-bottom: 40px;
				// text-align: left;
				margin-left: 20px;
			}
		}
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}
</style>
