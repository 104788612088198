<template>
	<div>
		<myNav/>
		<login-pop/>
		<div class="content">
			<div>
				<div class="a_all" style="margin-bottom:30px">
					<div class="a_title1">首页-院校查询</div>
					<div class="a_title5" style="margin-top: 47px;">
						<div class="a_title6" style="margin-top:7px;">省份选择：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck1==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in listData.data ? listData.data.provinces.data : ''" :key="index" @click="isOk1(index,item)">{{item.title}}</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="display: flex;align-items: center;">学历级别：</div>
						<div style="display: flex;flex-wrap: wrap;text-align: left;width: 1200px;">
							<div :class="isCheck2==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in myList.bf_type" :key="index" @click="isOk2(index,item)">{{item}}</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">高校级别：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck3==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in listData.data ? listData.data.schoolTag : ''" :key="index" @click="isOk3(index,item)">{{item}}</div>
						</div>
					</div>
					<!-- <div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">办学性质：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck4==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in searchCondition.bxxz" :key="index" @click="isOk4(index)">公办</div>
						</div>
					</div> -->
					<!-- <div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">高校隶属：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck5==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in 6" :key="index" @click="isOk5(index)">教育部</div>
						</div>
					</div> -->
					<!-- <div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">学校类型：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck6==index ? 'a_title8' : 'a_title7'" v-for="(item,index) in searchCondition.stype" :key="index" @click="isOk6(index)">农业</div>
						</div>
					</div> -->
				</div>
				<div v-loading="loading" :style="loading ? {'padding-top':'100px','background':'#FFFFFF'} : ''"></div>
				<div class="c_content" v-for="(item,index) in searchCondition.data" :key="index" @click="myDetail(item)">
					<div class="cc_all">
						<div><img class="cc_img" :src="item.logo" alt=""></div>
						<div class="cc_title1">
							<div style="display: flex;justify-content: space-between;align-items: center;">
								<div>
									<div style="font-size:18px;font-weight:600;">{{item.schoolName}}</div>
									<div class="cc_title2">{{item.schoolNam}}</div>
									<div class="cc_title3">{{item.provinceName}}| {{item.address}} | {{item.diplomaName}}</div>
									<div>{{item.schoolAttributeName}},{{item.schoolCategoryName}}</div>
								</div>
								<div class="cc_title4" v-if="item.schoolTag">
									<div style="margin-top:5px;padding:5px 0px;color: #FFFF00;background-color: #00B0E8;border-radius:10px;width:200px;text-align: center;">{{item.schoolTag}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="search_all">
					<el-pagination
					background
					layout="prev,pager,next"
					:total="searchCondition.total"
					:page-size="15"
					:pager-count="7"
					:current-page="searchCondition.current_page"
					@current-change="currentChange"
					>
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				loading:true,
				myList:{bf_type:['本科','专科']},
				isCheck1:null,
				isCheck2:null,
				isCheck3:null,
				currentPage:1,
				searchCondition: {}, // 筛选条件
				listData:[],
				form:{}
			}
		},
		activated(){
			this.isCheck1=null,
			this.isCheck2=null,
			this.isCheck3=null,
			this.form.page=1
			// 获取筛选列表
			this.$axios({
				url:'art/index',
				method: 'POST',
				data:{
					page:1
				}
			}).then(res=>{
				this.searchCondition = res.data.data
				this.loading=false
			})
			this.dataAll()
		},
		methods: {
			//省份选中状态
			isOk1(e,data){
				this.loading=true
				this.isCheck1=e
				this.form.schoolName=data.title;
				this.form.page=1;
				this.$axios({
					url:'art/index',
					method:'post',
					data:this.form
				}).then(res=>{
					this.searchCondition=res.data.data
					this.loading=false
				})
			},
			//本科，专科查询
			isOk2(e){
				this.loading=true
				this.isCheck2=e
				this.form.diploma=e+1;
				this.form.page=1;
				this.$axios({
					url:'art/index',
					method:'post',
					data:this.form
				}).then(res=>{
					this.searchCondition=res.data.data
					this.loading=false
				})
			},
			isOk3(e,data){
				this.loading=true
				this.isCheck3=e
				this.form.schoolTagbd=data;
				this.form.page=1
				this.$axios({
					url:'art/index',
					method:'post',
					data:this.form
				}).then(res=>{
					this.searchCondition=res.data.data
					this.loading=false
				})
			},
			dataAll(){
				this.$axios({
					url:'art/getSearchTerm',
					method:'post'
				}).then(res=>{
					this.listData=res.data
				})
			},
			//改变当前页的方法
			currentChange(val){
				this.loading=true
				this.currentPage=val;
				this.form.page=val
				this.$axios({
					url:'art/index',
					method:'post',
					data:this.form
				}).then(res=>{
					this.searchCondition=res.data.data
					this.loading=false
				})
			},
			//跳转艺术详情页面
			myDetail(e){
				this.$router.push({path:'/art/artDetail',query:{mid:e.id,id:e.schoolID,data:e.logo}})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;
				.a_title6{
					width: 130px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
				}
				.a_title7:hover{
					cursor: pointer;
				}
				.a_title7{
					font-size: 22px;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
				.a_title8:hover{
					cursor: pointer;
				}
				.a_title8{
					font-size: 22px;
					font-weight: 400;
					line-height: 43px;
					color:#337CFF;
					margin-left: 47px;
				}
			}
		}
		.b_all{
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;
			.b_title1{
				font-size:24px;
				font-weight: 400;
				color: #666666;
			}
			.b_title2{
				font-size:24px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.c_content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;
			.c_all{
				display: flex;
				align-items: center;
				.c_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.c_title1{
					text-align: left;
					.c_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
					}
					.c_title3{
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}
					.c_title4{
						display: flex;
						align-items: center;
						.c_title5{
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title6{
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title7{
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color:#3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title8{
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;	
							font-size: 14px;
							font-weight: 400;
							color:#3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.cc_all{
				display: flex;
				align-items: center;
				.cc_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.cc_title1{
					width:900px;
					.cc_title2{
						font-size: 20px;
						font-weight: 400;
						color: #333333;
						text-align: left;
					}
					.cc_title3{
						font-size:14px;
						font-weight: 400;
						color: #999999;
						margin: 12px 0px;
						text-align: left;
					}
					.cc_title4{
						// display: flex;
						// flex-wrap: wrap;
						.cc_title5{
						background: #F0F5FF;
						padding: 5px 10px;
						border-radius: 14px;
						margin-right: 12px;
						margin-bottom: 12px;
						font-size: 12px;
						font-weight: 400;
						color: #337CFF;
						}
					}
				}
			}
			.c_title9{
				.iconfont{
					color: #FECB1E;
					font-size: 30px;
				}
				.c_title10{
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}
		.search_all{
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;
		.search{
			width: 350px;
			height: 83px;
			background: #337CFF;
			opacity: 1;
			border-radius: 7px;
			font-size:28px;
			font-weight: 400;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}	
		}
		.a_content{
			display: flex;
			margin-top: 50px;
			height: 100px;
			align-items: center;
			.a_title9{
				font-size:30px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.d_content{
			width: 1400px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;
			.d_title1{
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}
			.d_title2{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}
		.f_content{
			width: 1200px;
			height: 53px;
			background: #F2F8FF;
			font-size: 24px;
			font-weight: 400;
			color: #333333;
			padding: 23px 49px;
			box-sizing: border-box;
			display: flex;
			text-align: left;
			align-items: center;
			.f_title2{
			font-size: 24px;
			font-weight: 400;
			color: #333333;	
			}
			.f_title3{
				font-size: 20px;
				font-weight: 400;
				color: #337CFF;
				margin-left: 58px;
			}
		}
		.f_all{
			display: flex;
			padding: 23px 49px 0px 49px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			width: 1200px;
			flex-wrap: wrap;
			.f_title1{
				font-size:16px;
				font-weight: 400;
				color: #999999;
				margin: 0px 40px 23px 0px;
			}
		}
		.g_content{
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;
			.g_title9{
				font-size:24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.k_img{
			width: 1200px;
			height: 549px;
			object-fit: cover;
		}
		.k_content{
			display: flex;
			justify-content: space-between;
			background-color: #FFFFFF;
			padding: 40px 3px;
			box-sizing: border-box;
			margin-top: 30px;
			.k_all{
				text-align: center;
				.k_title1{
					width: 163px;
					height: 163px;
					background: #337CFF;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					.iconfont{
						color: #FFFFFF;
						font-size: 60px;
					}
				}
				.k_title2{
					margin-top: 23px;
					font-size: 24px;
					font-weight: 400;
					color: #000000;
				}
			}
		}
		.k_title3{
			width: 1200px;
			height: 55px;
			background: #F2F8FF;
			padding-left:49px;
			box-sizing: border-box;
			font-size:22px;
			font-weight: 400;
			color: #333333;
			display: flex;
			align-items: center;
		}
		.k_title4{
			background-color: #FFFFFF;
			padding: 0px 49px 30px 49px;
			box-sizing: border-box;
			.k_title5{
				font-size:18px;
				font-weight: 400;
				color: #999999;
				padding-top: 23px;
				text-align: left;
			}
		}
	}
</style>
