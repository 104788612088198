<template>
	<div>
		<div>
			<myNav/>
			<login-pop/> <!-- 未登录弹出 -->
			<div class="a_content" :style="list.length==0 ? {'height' : '800px'} : ''">
				<div class="a_title1">共包含{{list.length}}个专业</div>
				<div @click="proDetail(item)" class="a_all" v-for="(item,index) in list" :key="index">
					<div class="a_title2">{{item.major_title}}</div>
					<div class="a_title3">
						<span class="a_title4">学制：{{item.system}}</span>
						<span class="a_title4">人气：{{item.major_mood}}</span>
						<span class="a_title4">男女比例：{{item.man_ratio}} : {{item.woman_ratio}}</span>
					</div>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				id:'',
				list:{}
			}
		},
		activated() {
			this.id=this.$route.query.id
			this.$axios({
				url:'major/index',
				method:'post',
				data:{
					level_id:this.id
				}
			}).then(res=>{
				this.list=res.data.data.data
			})
		},
		methods: {
			//跳转专业详情
			proDetail(e){
				this.$router.push({path:'/mySearch/zhuanDetail',query:{id:e.id}})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	body{
		background-color: #FFFFFF !important;
	}
.a_content{
	width: 1200px;
	background-color: #FFFFFF;
	margin: auto;
	.a_title1{
	font-size: 18px;
	font-weight: 400;
	color: #333333;
	padding-top: 40px;
	padding-left: 30px;
	box-sizing: border-box;
	}
	.a_all{
		margin-top: 30px;
		padding-left: 30px;
		box-sizing: border-box;
		padding-bottom: 30px;
		border-bottom: 1px solid #F5F5F5;
	.a_title2{
	font-size:16px;
	font-weight: 400;
	color: #333333;
		margin-bottom: 10px;
	}	
	.a_title3{
		display: flex;
		align-items: center;
		.a_title4{
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		margin-right:20px;
		}
	}
	}
}
</style>
