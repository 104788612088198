<template>
	<div>
		<myNav/>
		<login-pop/>
		<div class="content">
			<div>
				<div class="a_all">
					<div class="a_title1">首页-院校查询</div>
					<!-- <div class="a_title5" style="margin-top: 47px;">
						<div class="a_title6" style="margin-top:7px;">省份选择：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1200px;">
							<div :class="isCheck==id ? 'a_title8' : 'a_title7'"
							v-for="(item,id) in searchCondition.province" :key="id"
							@click="isOk(id)">{{item}}</div>
						</div>
					</div> -->
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="display: flex;align-items: center;">学历级别：</div>
						<div style="display: flex;flex-wrap: wrap;text-align: left;width: 1000px;">
							<div :class="filter.school_type==id ? 'a_title8' : 'a_title7'"
							v-for="(item,id) in searchCondition.bf_type" :key="id"
							@click="getList('school_type',id)">{{item}}</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">高校级别：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div v-for="(item,index) in searchCondition.label" :key="index"
							:class="filter[index]==item.value ? 'a_title8' : 'a_title7'"
							@click="getList(index,item.value)">{{item.name}}</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">办学性质：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="filter.is_pubilc==index ? 'a_title8' : 'a_title7'"
							v-for="(item,index) in searchCondition.bxxz" :key="index"
							@click="getList('is_pubilc',index)">{{item}}</div>
						</div>
					</div>
					<div class="a_title5" style="margin-top: 27px;">
						<div class="a_title6" style="margin-top:7px;">学校类型：</div>
						<div style="display: flex;flex-wrap: wrap;width: 1000px;">
							<div :class="filter.type==index ? 'a_title8' : 'a_title7'"
							v-for="(item,index) in searchCondition.stype" :key="index"
							@click="getList('type',index)">{{item}}</div>

						</div>
					</div>
				</div>
				<div class="b_all">
					<span class="b_title1">已为你找到 </span>
					<span class="b_title2">{{college.total}}</span>
					<span class="b_title1">所大学</span>
				</div>
				<div class="c_content" v-for="(item,index) in college.list" :key="index" @click="detail(item)">
					<div class="c_all">
						<div><img class="c_img" :src="item.logo" alt=""></div>
						<div class="c_title1">
							<div class="c_title2">{{item.title}}</div>
							<div class="c_title3">{{item.province_name}}</div>
							<div class="c_title4">
								<div class="c_title5" v-if="item.label.includes('公办')">公办 </div>
								<div class="c_title6" v-if="item.f211 == 1">985</div>
								<div class="c_title7" v-if="item.f985 == 1">211</div>
								<div class="c_title8" v-if="item.dual_class">双一流</div>
							</div>
						</div>
					</div>
					<div class="c_title9">
						<i class="iconfont icon-mn_paiming_fill"></i>
						<span class="c_title10">排名{{item.zr_rank}}</span>
					</div>
				</div>
				<!-- <div class="search_all" style="margin-top:50px;display: flex;justify-content: center;">
				<el-pagination
				background
				layout="prev,pager,next"
				:total="listData.length"
				:page-size="4"
				:pager-count="5"
				:current-page="1"
				@current-change="currentChange"
				>
				</el-pagination>
				</div> -->
				<div class="search_all"><div class="search" @click="getList()">查看更多</div></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},

		data() {
			return {
				index1:0,
				school:1,
				currentPage:1,
				isCheck:0,
				isCheck1:1,
				list1:[{title:'哲学'},{title:'经济学'},{title:'法学'},{title:'教育学'},{title:'文学'},{title:'工学'},{title:'农学'},{title:'医学'},{title:'军事学'},{title:'管理学'},{title:'艺术学'},{title:'其他'}],
				list2:[{name:'学校概括'},{name:'录取信息'},{name:'招生计划'},{name:'选考要求'},{name:'简章查询'},{name:'高校就业'}],
				// 学校列表 - 详情
				province_list: [],
				searchCondition: {}, // 筛选条件
				college: {list: [],current: 1,total: 0,detail: {}}, // 学校列表
				filter: {
					school_type: '',
					is_pubilc: '',
					dual_class: '',
					f211: '',
					f985: '',
					type: ''
				},

				// 专业
				group: {
					list: [],
					type: 1
				}
			}
		},
		activated(){
			// 获取筛选列表
			this.$axios({
				url:'/School/searchCondition',
				method: 'POST',
			}).then(res=>{
				this.searchCondition = res.data.data
			})
			this.getList()
			this.majorGroup()
		},
		methods: {
			getList (k = '',v = '') {
				if (this.filter[k] == v) {
					this.filter[k] = ''
				} else {
					this.filter[k] = v
					this.college.current=1
				}
				Object.assign(this.filter,{page: this.college.current})
				this.$axios({
					url:'/school/index',
					method: 'POST',
					data: this.filter
				}).then(res=>{
					if (k) this.college.list = [],this.college.current = 1,this.college.total = ''
					if (res.data.data.current_page <= res.data.data.last_page) {
						this.college.list = this.college.list.concat(res.data.data.data)
						this.college.current++
						if (!this.college.total) this.college.total = res.data.data.total
					}
					// console.log(this.list);
				})

			},
			majorGroup (i = 1) {
				this.group.type = i
				this.$axios({
					url: '/major/group',
					method: 'POST',
					data: {
						type: this.group.type
					}
				}).then(res => {
					this.group.list = res.data.data
				})
			},
			//学校详情
			detail(e){
				this.$store.commit('detail',{school_id:e.school_id,spare_type:e.spare_type})
				this.$router.push({path:'/Detail',query:{id:e.school_id}})
			},
			xuanZe(e){
				this.index1=e
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		.top_img{
			width: 1200px;
			height: 500px;
		}
		.img_title1{
			background-color: #FFFFFF;
			padding: 25px 40px 0px 40px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			.img_title2{
				width: 970px;
				height: 55px;
				background-color: #F2F8FF;
				.img_title3{
					width: 1140px;
					height: 50px;
					background-color: #F2F8FF;
				}
			}
			.img_title4{
				display: flex;
				align-items: center;
				width: 152px;
				height: 55px;
				background: #3B68DF;
				justify-content: center;
				.iconfont{
					color: #FFFFFF;
				}
				.img_title5{
					font-size:16px;
					font-weight: 400;
					color: #FFFFFF;
					margin-left: 8px;
				}
			}
		}
		.a_all {
			padding: 35px 19px;
			box-sizing: border-box;
			background-color: #FFFFFF;

			.a_title1 {
				font-size: 24px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}

			.a_title2 {
				display: flex;
				align-items: center;

				.a_title3 {
					margin: 47px 0px;
					font-size: 22px;
					font-weight: 400;
					color: #333333;
				}

				.a_title4 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 43px;
					color: #999999;
					margin-left: 47px;
				}
			}

			.a_title5 {
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				display: flex;
				flex-wrap: wrap;
				.a_title6{
					font-size: 22px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					display: flex;
					margin-top: 7px;
				}
				.a_title7{
					font-size: 22px;
					font-weight: 400;
					color: #999999;
					margin-left: 47px;
				}
				.a_title8:hover{
					color: #F58235;
					cursor: pointer;
				}
				.a_title7:hover{
					color: #F58235;
					cursor: pointer;
				}
				.a_title8{
					font-size: 22px;
					font-weight: 400;
					color:#337CFF;
					margin-left: 47px;
				}
			}
		}
		.b_all{
			display: flex;
			align-items: center;
			margin: 58px 0px 23px 34px;
			.b_title1{
				font-size:24px;
				font-weight: 400;
				color: #666666;
			}
			.b_title2{
				font-size:24px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.c_content{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 35px 36px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			border-bottom: 1px solid #F5F5F5;
			.c_all{
				display: flex;
				align-items: center;
				.c_img{
					width: 110px;
					height: 110px;
					border-radius: 50%;
					margin-right: 23px;
				}
				.c_title1{
					text-align: left;
					.c_title2{
						font-size:20px;
						font-weight: 400;
						color: #333333;
					}
					.c_title3{
						margin: 10px 0px;
						font-size: 18px;
						font-weight: 400;
						color: #999999;
					}
					.c_title4{
						display: flex;
						align-items: center;
						.c_title5{
							width: 41px;
							height: 24px;
							background: #F0F5FF;
							opacity: 1;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #337CFF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title6{
							width: 37px;
							height: 24px;
							background: #FFF1F0;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color: #FF4D4F;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title7{
							width: 33px;
							height: 24px;
							background: #DDF7E2;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3DB159;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.c_title8{
							width: 55px;
							height: 24px;
							background: #F0F5FF;
							border-radius: 5px;
							font-size: 14px;
							font-weight: 400;
							color:#3B68DF;
							margin-right: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			.c_title9{
				.iconfont{
					color: #FECB1E;
					font-size: 30px;
				}
				.c_title10{
					font-size: 30px;
					font-weight: 400;
					margin-left: 9px;
					color: #FECB1E;
				}
			}
		}
		.search_all{
			height: 179px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFFFFF;
		.search{
			width: 350px;
			height:50px;
			background: #337CFF;
			opacity: 1;
			border-radius: 7px;
			font-size:20px;
			font-weight: 400;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor:pointer;
		}
		}
		.a_content{
			display: flex;
			// margin-top: 50px;
			height: 100px;
			align-items: center;
			.a_title9{
				font-size:24px;
				font-weight: 400;
				color: #666666;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}
		.d_content{
			width: 1200px;
			background: #F0F5FF;
			border: 5px solid #407EF6;
			padding: 35px 45px;
			box-sizing: border-box;
			margin: 35px 0px;
			.d_title1{
				font-size: 24px;
				font-weight: 500;
				color: #337CFF;
				text-align: left;
			}
			.d_title2{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 35px;
				color: #666666;
				margin-top: 20px;
				text-align: left;
			}
		}
		.f_content{
			width: 1200px;
			padding: 35px 41px;
			background: #FFFFFF;
			box-sizing: border-box;
			font-size:20px;
			font-weight: 400;
			color: #666666;
			text-align: left;
		}
		.f_title1{
			display: flex;
			align-items: center;
			width: 1200px;
			height: 73px;
			background: #F2F8FF;
			margin-top: 35px;
			.f_title5{
				font-size:20px;
				font-weight: 400;
				margin:0px 58px 0px 49px;
			}
			.f_title6{
				font-size: 18px;
				font-weight: 400;
				color: #337CFF;
			}
			// .f_title7{
			// 	.f_title8{

			// 	}
			// }
			.f_title2{
				width: 289px;
				height: 60px;
				background: #337CFF;
				font-size:20px;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.f_title3{
				width: 289px;
				height: 60px;
				background: #F5F5F5;
				font-size:20px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.f_all{
			display: flex;
			flex-wrap: wrap;
			padding: 35px;
			box-sizing: border-box;
			background: #FFFFFF;
			.f_title4{
				padding: 12px 47px;
				box-sizing: border-box;
				background: #F3F5F6;
				font-size: 18px;
				font-weight: 400;
				color: #999999;
				margin: 0px 35px 23px 0px;
			}
		}
		.g_content{
			background-color: #FFFFFF;
			width: 1200px;
			margin: auto;
			padding: 30px 0px;
			box-sizing: border-box;
			.g_all{
				display: flex;
				align-items: center;
				.g_img{
					width: 100px;
					height: 100px;
					margin:0px 20px 0px 30px;
					border-radius: 50%;
				}
				.g_title1{
					.g_title2{
						display: flex;
						align-items: center;
						.g_title3{
							font-size:24px;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						.g_title4{
							width: 47px;
							height: 21px;
							background: #337CFF;
							border-radius: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.g_title5{
						display: flex;
						align-items: center;
						margin-top: 26px;
						.g_title6{
							font-size: 16px;
							font-weight: 400;
							color: #999999;
							margin-right: 30px;
						}
					}
				}
			}
			.g_title7{
				width: 1140px;
				height: 0px;
				border: 2px solid #CCCCCC;
				margin:22px auto;
			}
			.gg_content{
				padding: 0px 30px;
				box-sizing: border-box;
				background-color: #FFFFFF;
				.gg_title1{
					font-size: 20px;
					font-weight: 400;
					color: #3B68DF;
					margin-bottom: 10px;
					text-align: left;
				}
				.gg_all{
					display: flex;
					.gg_title2{
						margin-right: 108px;
						text-align: left;
						.gg_title3{
							font-size: 16px;
							font-weight: 400;
							color: #666666;
							margin-bottom: 10px;
						}
					}
				}
			}
			.g_content1{
				display: flex;
				justify-content:space-between;
				.g_next1{
					width: 200px;
					height: 55px;
					background: #F2F8FF;
					font-size: 20px;
					font-weight: 400;
					color: #337CFF;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.g_next2{
					width: 200px;
					height: 55px;
					font-size: 20px;
					font-weight: 400;
					color:#333333;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.g_title8{
				width: 1200px;
				height: 2px;
				background: #337CFF;
			}
			.g_title9{
				.g_title10{
					font-size: 24px;
					font-weight: 400;
					color: #333333;
					margin: 30px 0px 20px 30px;
					text-align: left;
				}
				.g_title11{
					font-size: 20px;
					font-weight: 400;
					color: #333333;
					margin:0px 30px 0px 30px;
				}
			}
		}
	}
</style>
