<template>
	<div>
		<myNav/>
		<login-pop/>
		<vipPop/>
		<div class="content" style="margin: 30px auto;">
			<div class="a_content">
				<div class="a_one">
					测试题类型
				</div>
				<div class="a_title1">
					<div @ @click="isOk(index,item)" :class="isCheck==index ? 'a_title2' : 'a_title3'" v-for="(item,index) in listData.list" :key="index">{{item.title}}</div>
				</div>
				<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
				<el-pagination
				background
				hide-on-single-page
				layout="prev,pager,next"
				:total="listData.total"
				:page-size="10"
				:pager-count="7"
				:current-page="listData.page"
				@current-change="currentChange"
				>
				</el-pagination>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	export default {
		components: {
			Footer
		},

		data() {
			return {
				isCheck:0,
				show:false,
				page:1,
				listData:[]
			}
		},
		created(){
			
		},
		activated(){
			if(!this.$store.state.userData.token) return
			if(this.$store.state.memberData.is_vip!=1) return 
			this.dataAll()
		},
		methods: {
			//查看更多
			dataAll(){
				this.$axios({
					url:'review_api/evaluation_contents',
					methods:'post',
					data:{
						page:this.page
					}
				}).then(res=>{
					this.listData=res.data.data
				})
			},
			//分页
			currentChange(e){
				this.$axios({
					url:'review_api/evaluation_contents',
					methods:'post',
					data:{
						page:e
					}
				}).then(res=>{
					this.listData=res.data.data
				})
			},
			//选中标题
		isOk(e,a){
			this.isCheck=e,
			this.show=true,
			this.$store.commit('textIndex',a)
			this.$router.push({path:'/ceshi/introduced'})
		}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
	.content {
		//width: 1200px;
	.a_content{
		width:1200px;
		border: 0.0625rem solid rgb(223,235,255);
		background-color: white;
		margin: auto;
		.a_one{
			width: 1200px;
			height:50px;
			background: #F2F8FF;
			font-size: 20px;
			line-height:50px;
			padding-left: 20px;
			box-sizing: border-box;
		}
		.a_title1{
			width:1200px;
			// margin: 20px;
			padding: 20px;
			box-sizing: border-box;
			.a_title2{
				width:100%;
				height: 100px;
				background: #337CFF;
				display: flex;
				align-items: center;
				padding-left: 40px;
				box-sizing: border-box;
				font-size:20px;
				font-weight: 400;
				color: #FFFFFF;
				margin-bottom: 20px;
				border-radius:10px;
			}
			.a_title3:hover{
				background: #337CFF;
				color: #FFFFFF;
			}
			.a_title3{
				width:100%;
				height: 100px;
				margin-bottom: 15px;
				background:#F5F5F5;
				display: flex;
				align-items: center;
				padding-left: 40px;
				box-sizing: border-box;
				font-size: 20px;
				font-weight: 400;
				color:#333333;
				box-sizing: border-box;
				border-radius:10px;
				
			}
		}
		.searchMore{
			width: 357px;
			height: 50px;
			background: #3B68DF;
			border-radius: 5px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			margin: 40px auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	}
</style>
