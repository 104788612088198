  <template>
	<keep-alive>
		<router-view></router-view>
	</keep-alive>
</template>

<script>
export default {
	name: 'App',
	data(){
	return{

	}
	},
	created() {
		
	}
}
</script>

<style>
/*每个页面公共css */
	@import url("./common/iconfont.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
body{
  background: #F5F5F5;
}
*{
        padding:0;
        margin: 0px;
    }
</style>
