<template>
<div>
	<myNav/>
	<loginPop/>
	<div class="tt_content">
		<div class="a_title1">
			<div v-for="(item,index) in listData" :key="index" :class="index1==index ? 'a_title2' : 'aa_title2'" @click="fen(index)">{{item.name}}</div>
		</div>
		<div class="c_content">
			<div class="c_all">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="一级门类：">
				<el-select v-model="formInline.name" placeholder="哲学">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
			<div class="c_all">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="二级类别：">
				<el-select v-model="formInline.name" placeholder="哲学类">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
			<div class="c_all">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="三级专业：">
				<el-select v-model="formInline.name" placeholder="哲学">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="d_content">
			<div class="d_title1">一级门类：</div>
			<div>
				<input class="d_title2" type="text" placeholder="请输专业名称">
			</div>
			<div class="d_title3">
				<i class="iconfont icon-search"></i>
				<span class="d_title4">搜索</span>
			</div>
		</div>
		<div class="f_content">
			<span class="f_title1">共检索出</span>
			<span class="f_title2">10</span>
			<span class="f_title1">条数据</span>
		</div>
		<div class="g_content">
			<div style="margin: auto;">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
				<el-select v-model="formInline.name" placeholder="山东">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
			<div style="margin: auto;">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
				<el-select v-model="formInline.name" placeholder="2019">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
			<div style="margin: auto;">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
				<el-select v-model="formInline.name" placeholder="理科">
				<el-option label="区域一" value="shanghai"></el-option>
				<el-option label="区域二" value="beijing"></el-option>
				</el-select>
				</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="a_title9">
			<div class="a_title10" v-for="(item,index) in titleData" :key="index">{{item.name}}</div>
		</div>
		<div class="a_title9" v-for="(item,index) in 6" :key="index">
			<div class="a_title10" style="background-color: #FFFFFF;" v-for="(item,index) in tableData" :key="index">{{item.name}}</div>
		</div>
		<div class="search_all" style="padding:50px;display: flex;justify-content: center;">
		<el-pagination
		background
		hide-on-single-page
		layout="prev,pager,next"
		:total="100"
		:page-size="4"
		:pager-count="7"
		:current-page="1"
		@current-change="currentChange"
		>
		</el-pagination>
		</div>
	</div>
	<Footer/>
</div>
</template>
<script>
	import Footer from '@/components/footer'
export default {
components: {
	Footer
},
data(){
	return {
		formInline:{name:''},
		index1:0,
		isCheck:0,
		listData:[{name:'同院校分析(文理)'},{name:'同院校分析(新高考)'}],
		titleData:[{name:'年份'},{name:'院校名称'},{name:'专业名称'},{name:'科目'},{name:'批次'},{name:'最低分'},{name:'最低分线差'},{name:'最低分位差'},{name:'招生人数'},{name:'操作'}],
		tableData:[{name:'2019'},{name:'北京大学'},{name:'理科实验班'},{name:'理科'},{name:'本科批'},{name:'691'},{name:'248'},{name:'46'},{name:'-'},{name:'加入对比'}]
	};
	},
	activated() {
		if(!this.$store.state.userData.token) return
	},
	methods:{
		fen(e){
			this.index1=e
		},
		//选中状态
		isOk(e){
			this.isCheck=e
		},
		//分页
		currentChange(e){
			console.log(e)
		}
	},
}

</script>

<style lang="less" scoped>
	.tt_content{
		width: 1200px;
		margin: auto;
		background-color: #FFFFFF;
		.a_title1{
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-left: 20px;
			box-sizing: border-box;
			.a_title2{
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
				border-bottom: 2px solid #3B68DF;
				color:#3B68DF;
			}
			.aa_title2{
				font-size: 24px;
				font-weight: 400;
				color: #333333;
				margin-right: 39px;
				padding-bottom: 20px;
			}
		}
		.c_content{
			display: flex;
			align-items: center;
			margin: 36px 0px 30px 30px;
			.c_all{
				margin-right: 20px;
			}
		}
		.d_content{
			display: flex;
			align-items: center;
			.d_title1{
				font-size: 14px;
				margin-left: 32px;
				margin-right: 10px;
			}
			.d_title2{
				width: 817px;
				height: 52px;
				background: #F7F7F7;
				border: 1px solid #ADADAD;
				border: none;
				outline: none;
			}
			.d_title3{
				display: flex;
				align-items: center;
				width: 130px;
				height: 52px;
				background: #3B68DF;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				margin-left:93px;
				justify-content: center;
			}
		}
		.f_content{
			display: flex;
			align-items: center;
			width: 1100px;
			height: 52px;
			background: #F7F7F7;
			border: 1px solid #ADADAD;
			justify-content: center;
			margin: 30px auto;
			.f_title1{
				font-size: 20px;
				font-weight: 400;
				color: #333333;
			}
			.f_title2{
				font-size: 20px;
				font-weight: 400;
				color:#3B68DF;
			}
		}
		.g_content{
			display: flex;
			align-items: center;
			justify-content: end;
			text-align: right;
		}
		.a_title9{
			display: flex;
			justify-content: center;
			.a_title10{
				width: 120px;
				height: 55px;
				background: #F7F7F7;
				border: 1px solid #EBEBEB;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
			}
		}
	}
</style>
