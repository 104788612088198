<template>
<div>
	<myNav />
	<login-pop/><!-- 跳转登录 -->
	<div class="a_content">
		<div class="a_all">
			<div><img class="a_img" :src="list.logo" alt=""></div>
			<div class="a_title1">
				<div class="a_title2">{{list.title}}</div>
				<div class="a_title3">
					<span class="a_title4" v-for="(item,index) in list.label" :key="index">{{item}}</span>
				</div>
				<div class="a_title6" v-if="list.f985==1">985工程</div>
			</div>
		</div>
		<div class="b_content">
			<div class="b_title1">学校名称</div>
			<div class="b_title1">选科建议</div>
		</div>
		<div class="b_content" v-for="(i,myIndex) in myList.data" :key="myIndex">
			<div class="b_title2">{{i.major_title}}</div>
			<div class="b_title2">{{i.optional_advise}}</div>
		</div>
		<div class="search_all" style="display: flex;align-items: center;justify-content: center;padding: 50px 0px;">
		<el-pagination
		background
		layout="prev,pager,next"
		:total="myList.total"
		:page-size="20"
		:pager-count="7"
		:current-page="myList.current_page"
		@current-change="currentChange3"
		>
		</el-pagination>
		</div>
	</div>
	<Footer />
</div>
</template>

<script>
	import Footer from '@/components/footer.vue'
	export default {
		components: {
			Footer
		},
		data() {
			return {
				list:[],
				myList:[]
			}
		},
		activated() {
			this.school_id=this.$route.query.id
			this.dataAll(this.school_id)
		},
		methods: {
			dataAll(e){
				this.$axios({
					url:'NewCneProvince/Getschool',
					method:'post',
					data:{
						school_id:e
					}
				}).then(res=>{
					this.list=res.data.data.data[0]
				})
				//专业列表
				this.$axios({
					url:'NewCneProvince/schoolS',
					method:'post',
					data:{
						page:1,
						school_id:e
					}
				}).then(res=>{
					this.myList=res.data.data
				})
			},
			//分页
			currentChange3(val){
				//专业列表
				this.$axios({
					url:'NewCneProvince/schoolS',
					method:'post',
					data:{
						page:val,
						school_id:this.school_id
					}
				}).then(res=>{	
					this.myList=res.data.data
				})
			}
		}
	}
</script>
<style lang="less" scoped>
.a_content {
	width: 1200px;
	margin: 20px auto;
	background-color: #FFFFFF;
	.a_all{
		width: 160px;
		width: 1200px;
		background-color: #FFFFFF;
		padding: 30px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	.a_img{
		width: 100px;
		height: 100px;
		background: #A51616;
		margin-right: 22px;
		border-radius: 50%;
	}
	.a_title1{
		.a_title2{
		font-size:20px;
		font-weight: 400;
		color: #333333;
		}
		.a_title3{
		display: flex;
		margin: 10px 0px;
		.a_title4{
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			margin-right:15px;
		}
		}
		.a_title6{
			width: 63px;
			height: 23px;
			background: #DDF7E2;
			border-radius: 12px;
			font-size: 12px;
			font-weight: 400;
			color: #3DB159;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	}
	.b_content{
		display: flex;
		align-items: center;
		.b_title1{
		width: 600px;
		height:40px;
		background: #F2F8FF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size:18px;
		font-weight: 400;
		color: #333333;
		letter-spacing:10px;
		}
		.b_title2{
		width: 600px;
		height:40px;
		background:#FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size:18px;
		font-weight: 400;
		color:#666666;
		letter-spacing:5px;	
		border-bottom: 1px solid #F5F5F5;
		}
	}
}
</style>
