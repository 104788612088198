import { render, staticRenderFns } from "./resulte.vue?vue&type=template&id=63babfcb&scoped=true&"
import script from "./resulte.vue?vue&type=script&lang=js&"
export * from "./resulte.vue?vue&type=script&lang=js&"
import style0 from "./resulte.vue?vue&type=style&index=0&id=63babfcb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63babfcb",
  null
  
)

export default component.exports