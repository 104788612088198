import Vue from 'vue'
import VueRouter from 'vue-router'
import myIndex from '@/views/index'
import index from '@/views/index'
import padLogin from '@/components/login/login'
import Login from '@/components/login/registered'
import Universities from '@/components/mySearch/universities'
import Detail from '@/components/mySearch/shcoolDetail'
import myShcoll from '@/components/mySearch/myShcoll'
import Zhuanye from '@/components/mySearch/zhuanye'
import newsIndex from '@/views/news/index'//新闻详情
import news from '@/views/news/news'//新闻首页
import Colleges from '@/views/newGaokao/colleges'
import Measurement from '@/components/ceshi/measurement'//测评
import Test from '@/components/ceshi/test'//测评题
import introduced from '@/components/ceshi/introduced'//测评介绍
import resulte from '@/components/ceshi/resulte'//测评结果
import About from '@/components/about'//个人中心
import Search from '@/components/mySearch/search'
import ArtIndex from '@/views/artAll/artIndex'//艺术首页
import Learning from '@/views/artAll/searchYuan'//艺术院校
import professionalArt from '@/views/artAll/professionalArt'//艺术专业
import myVip from '@/components/VIP/index'//VIP首页
import oderVip from '@/components/VIP/oderVip'//VIP首页
import PayFor from '@/components/VIP/payFor'//VIP支付
import voulenter from '@/views/volunteer/volunteerIndex'//志愿填报
import yLine from '@/views/volunteer/yline'//志愿填报
import professional from '@/views/volunteer/professional'//
import archives from '@/views/volunteer/archives'//
import admissions from '@/views/volunteer/admissions'//招生计划
import conversion from '@/views/volunteer/conversion'//次位换算
import contact from '@/components/contact'//专家咨询
import newGao from '@/views/newGaokao/detail'//新高考
import newIndex from '@/views/newGaokao/index'//新高考详情页
import myDetails from '@/views/news/myDetail'//新高考首页
import Enter from '@/components/enter'//海外留学首页
import Analysis from '@/views/volunteer/Analysis'//同院校分析(新高考)
import souSuo from '@/views/search/index'//同院校查询
import appDownload from '@/views/download/appDownload'//app下载页面
import zhuanDetail from '@/components/mySearch/zhuanDetail'//专业详情
import rules from '@/components/mySearch/rules'//简章
import proList from '@/components/mySearch/proList'//专业列表
import searchPro from '@/views/newGaokao/searchPro'//大学专业搜索
import searchFesic from '@/views/newGaokao/searchFesic'//专业搜索
import searchList from '@/views/newGaokao/searchList'//查看大学列表
import fessicList from '@/views/newGaokao/fessicList'//查看专业详情
import privacy from '@/views/main/privacy'//隐私政策
import pdf from '@/views/main/pdf'//系统说明
import artDetail from '@/views/artAll/artDetail'//艺术详情页面
import isDetail from '@/views/artAll/isDetail'//艺术专业详情页面
import details from '@/views/artAll/y_detai'//艺术专业详情页面
import aboutMe from '@/views/main/aboutMe'//关于我们详情页面
import liability from '@/views/main/liability'//法律免责详情页面
import joinMe from '@/views/main/joinMe'//加入我们详情页面
import newColleges from '@/views/newGaokao/newCollege'//加入我们详情页面
Vue.use(VueRouter)
//

const routes = [{
		//首页样式
		path: '/myIndex',
		name: 'myIndex',
		component: myIndex,
		meta: {
			isLogin: true
		}
	},
	{
		path: '/',
		component: myIndex
	},
	{
		//需求首页样式
		path: '/index',
		name: 'index',
		component: index
	},
	{
		//注册路由
		path: '/padLogin',
		name: 'padLogin',
		component: padLogin
	},
	{
		//登录路由
		path: '/Login',
		name: 'Login',
		component: Login
	},
	{
		path: '/news',
		name: 'news',
		component: news
	},
	{//新高考
		path: '/new/Colleges',
		name: 'Colleges',
		component: Colleges
	},
	{//查询
		path: '/Search',
		name: 'Search',
		component: Search,
		// chirldren:[{
		// 	path:'/Universities',
		// 	name: Universities,
		// 	component: Universities
		// },
		// {
		// 	path:'/Zhuanye',
		// 	name: Zhuanye,
		// 	component: Zhuanye
		// },
		// ]
	},
	{//院校
		path: '/Universities',
		name: 'Universities',
		component: Universities
	},
	{//大学
		path: '/myShcoll',
		name: 'myShcoll',
		component: myShcoll
	},
	{//专业
		path: '/Zhuanye',
		name: 'Zhuanye',
		component: Zhuanye
	},
	{//个人中心
		path: '/About',
		name: 'About',
		component: About
	},
	{//艺术首页
		path: '/ArtIndex',
		name: 'ArtIndex',
		component: ArtIndex
	},
	{//测评首页
		path: '/Measurement',
		name: 'Measurement',
		component: Measurement
	},
	{//测评题
		path: '/ceshi/Test',
		name: 'Test',
		component: Test
	},
	{//VIP
		path: '/myVip',
		name: 'myVip',
		component: myVip
	},
	{//VIP
		path: '/oderVip',
		name: 'oderVip',
		component: oderVip
	},
	{//VIP支付
		path: '/PayFor',
		name: 'PayFor',
		component: PayFor
	},
	{//学校详情
		path: '/Detail',
		name: 'Detail',
		component: Detail
	},
	{//志愿填报
		path:'/volunteer/voulenter',
		name:'voulenter',
		component:voulenter
	},
	{//院校分数线(文理)
		path:'/volunteer/yLine',
		name:'yLine',
		component:yLine
	},
	{//专业分数线(文理)
		path:'/volunteer/archives',
		name:'archives',
		component:archives
	},
	{//
		path:'/volunteer/professional',
		name:'professional',
		component:professional
	},
	{//招生计划
		path:'/volunteer/admissions',
		name:'admissions',
		component:admissions
	},
	{//次位换算
		path:'/volunteer/conversion',
		name:'conversion',
		component:conversion
	},
	{//招生计划
		path:'/contact',
		name:'contact',
		component:contact
	},
	{//艺术院校查询
		path:'/Learning',
		name:'Learning',
		component:Learning
	},
	{//艺术专业查询
		path:'/professionalArt',
		name:'professionalArt',
		component:professionalArt
	},
	{//新高考
		path:'/newGao',
		name:'newGao',
		component:newGao
	},
	{//海外留学首页
		path:'/Enter',
		name:'Enter',
		component:Enter
	},
	{//同院校分析(新高考)
		path:'/Analysis',
		name:'Analysis',
		component:Analysis
	},
	{//测评介绍
		path:'/ceshi/introduced',
		name:'introduced',
		component:introduced
	},
	{//测评介绍
		path:'/ceshi/resulte',
		name:'resulte',
		component:resulte
	},
	{//院校搜索
		path:'/search/souSuo',
		name:'souSuo',
		component:souSuo
	},
	{//新闻首页
		path:'/news/newsIndex',
		name:'newsIndex',
		component:newsIndex
	},
	{//新闻首页
		path:'/news/myDetails',
		name:'myDetails',
		component:myDetails
	},
	{//新高考页面
		path:'/newGaokao/newIndex',
		name:'newIndex',
		component:newIndex
	},
	{//app下载页面
		path:'/download/appDownload',
		name:'appDownload',
		component:appDownload
	},
	{//app专业详情页面
		path:'/mySearch/zhuanDetail',
		name:'zhuanDetail',
		component:zhuanDetail
	},
	{//app专业详情页面
		path:'/mySearch/rules',
		name:'rules',
		component:rules
	},
	{//专业列表
		path:'/mySearch/proList',
		name:'proList',
		component:proList
	},
	{//专业搜索
		path:'/new/searchPro',
		name:'searchPro',
		component:searchPro
	},
	{//专业查看搜索
		path:'/new/searchFesic',
		name:'searchFesic',
		component:searchFesic
	},
	{//查看大学列表
		path:'/new/searchList',
		name:'searchList',
		component:searchList
	},
	{//查看大学列表
		path:'/new/fessicList',
		name:'fessicList',
		component:fessicList
	},
	{//隐私政策
		path:'/new/privacy',
		name:'privacy',
		component:privacy
	},
	{//隐私政策
		path:'/main/pdf',
		name:'pdf',
		component:pdf
	},
	{//艺术详情页面
		path:'/art/artDetail',
		name:'artDetail',
		component:artDetail
	},
	{//艺术专业详情页面
		path:'/art/isDetail',
		name:'isDetail',
		component:isDetail
	},
	{//艺术专业详情页面
		path:'/art/details',
		name:'details',
		component:details
	},
	{//法律免责详情页面
		path:'/new/liability',
		name:'liability',
		component:liability
	},
	{//加入我们详情页面
		path:'/new/joinMe',
		name:'joinMe',
		component:joinMe
	},
	{//关于我们详情页面
		path:'/new/aboutMe',
		name:'aboutMe',
		component:aboutMe
	},
	{//新高考
		path:'/new/newColleges',
		name:'newColleges',
		component:newColleges
	}
]

const router = new VueRouter({
	mode: 'history',
	routes,
	// 解决路由跳转后，不显示在首行
	scrollBehavior() {
	return {x: 0, y: 0};
	}
})
// 全局守卫，受否需要登录通行证
router.beforeEach((to, from, next) => {
	if (to.meta.isLogin) {
		router.push('/Index')
	} else {
		next()
	}
})
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
